import { BASE_URL, BASE_URL_SC_DEV } from './endpoints';
import { ExecutorInterface } from './entities';

class HttpService {
	private static USER_TOKEN_ENDPOINTS = [
		'/api/users',
		'/api/klub-dons/my-dons',
		'/api/klubr-donateurs/my-last',
		'/api/klub-projets',
		'/api/klubrs',
	];
	private static Token: string = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN as string;
	private static HeadersList = {
		Accept: 'application/json, application/xml, text/plain, text/html, *.*',
	};
	private static Headers = new Headers();

	public static resetToken(newToken?: string) {
		this.Token = newToken || (process.env.NEXT_PUBLIC_STRAPI_API_TOKEN as string);
	}

	public static async ExecuteRequest(params: ExecutorInterface): Promise<any> {
		await this.setup(params.endPoint, params.headers, params.isFormData);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await fetch(await this.constructUrl(params.endPoint, params.params), {
					method: params.method,
					headers: this.Headers,
					body: params.data instanceof FormData ? params.data : JSON.stringify(params.data),
					next: { tags: params.tags || undefined },
					cache: params.noCache ? 'no-cache' : 'default',
					// revalidate: params.revalidate || 3600,
				});

				if (response.ok) {
					let res = null;
					if (params.responseType === 'blob') {
						res = await response.blob();
						resolve({
							data: res,
							contentDisposition: response.headers.get('content-disposition') || '',
						});
					} else {
						res = await response.json();
						resolve(res);
					}
				} else {
					const res = await response.json();
					reject(res);
				}
			} catch (e) {
				reject(e);
			}
		});
	}

	private static async constructUrl(endpoint: string, params?: Record<string, any>) {
		return `${typeof window === 'undefined' ? BASE_URL_SC_DEV : BASE_URL}${endpoint}${new URLSearchParams(params)}`;
	}

	private static setup(
		endpoint: string,
		headers?: Record<string, any>,
		isFormData?: boolean,
	): Promise<true> {
		return new Promise((resolve) => {
			setTimeout(() => {
				this.resetToken(this.Token);
				this.Headers = new Headers({
					...this.HeadersList,
					Authorization: `Bearer ${this.USER_TOKEN_ENDPOINTS.some((_) => endpoint.startsWith(_) || endpoint.includes('/preview')) ? this.Token : (process.env.NEXT_PUBLIC_STRAPI_API_TOKEN as string)}`,
					...(isFormData
						? {}
						: {
								'Content-Type': 'application/json',
							}),
					...headers,
				});
				resolve(true);
			}, 0);
		});
	}
}

export default HttpService;
