export enum TagsEnum {
	'-------------------' = '-------------------',
	Club_ClubHouse_Slugs = 'Club_ClubHouse_Slugs',
	'------------------+' = '-------------------',
	ClubsSlugs = 'ClubsSlugs',
	AllClubsSlugs = 'AllClubsSlugs',
	ClubsDetailBySlug = 'ClubsDetailBySlug',
	AllClubs = 'AllClubs',
	ClubHouse = 'ClubHouse',
	'----------------------------------------' = '----------------------------------------',
	Mecenat_Contact_CGU_Cookies_HP = 'Mecenat_Contact_CGU_Cookies_HP',
	'---------------------------------------+' = '----------------------------------------',
	PageMecenat = 'PageMecenat',
	MecenatReassurance = 'MecenatReassurance',
	PageContact = 'PageContact',
	PageMesDons = 'PageMesDons',
	HP = 'HP',
	CGU = 'CGU',
	Cookies = 'Cookies',
	DON_CGU = 'DON_CGU',
	'--------' = '--------',
	DONATIONS = 'DONATIONS',
	'-------+' = '--------',
	DonsByClubOrProject = 'DonsByClubOrProject',
	GetDonation = 'GetDonation',
	GetMyLastDonation = 'GetMyLastDonation',
	GetMyDonations = 'GetMyDonations',
	'-------' = '-------',
	PROJECTS = 'PROJECTS',
	'------+' = '-------',
	GetProjectDetail = 'GetProjectDetail',
	GetProjectByClub = 'GetProjectByClub',
	GetAllProjectsSlugs = 'GetAllProjectsSlugs',
	GetAllProjectsSlugsWithClubSlug = 'GetAllProjectsSlugsWithClubSlug',
	GetProjects = 'GetProjects',
}
